import React from 'react'
import MainRoute from './component/MainRoute';

function App() {
  return (
    <MainRoute/>
  );
}

export default App;
