import React from 'react'

function Map() {
  return (
    <div>
      {/* <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3949.1185617913566!2d77.69731437500927!3d8.19081019184081!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s9%2F28-3%2C%20west%20street%2C%20koodankulam%2C%20radhapuram%20tk%2C%20tirunelveli%20dist%2C%20tamilnadu%2C%20pin-627106%20-%20longitude%20and%20altitude!5e0!3m2!1sen!2sin!4v1702277955278!5m2!1sen!2sin" width="100%" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
      <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3949.1185091138705!2d77.69731437467567!3d8.19081550154648!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s9%2F28-3%2C%20West%20Street%2C%20Koodankulam%2C%20Radhapuram%20Tk%2C%20Tirunelveli%20Dist%2C%20Tamilnadu%2C%20Pin-627106!5e0!3m2!1sen!2sin!4v1702279211205!5m2!1sen!2sin" width="100%" height="450"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="Google Map Location"></iframe>
    </div>
  )
}

export default Map
